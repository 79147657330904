import { graphql } from 'gatsby'
import React from 'react'
import Pagination from '../components/pagination/pagination'
import ListAll from '../components/list-all/list-all'
import SEO from '../components/seo/seo'
import Layout from '../containers/layout'

export const query = graphql`
  query PostListTemplateQuery($skip: Int = 0, $pageSize: Int = 4) {
    posts: allSanityPost(
        limit: $pageSize, skip: $skip
        sort: { fields: publishedAt, order: DESC }
      ) {
      edges {
        node {
          id
          title
          shortTitle
          slug {
            current
          }
          _rawMainImage
          excerpt
          categories {
            title
          }
        }
      }
      totalCount
    }

    site: sanitySiteSettings {
      keywords
      title
      description
    }
  }
`

const PostList = ({ data, pageContext }) => {
  const { site, posts } = data

  return (
    <Layout>
      <SEO title={`Projects | ${site?.title}`} description={site?.description} keywords={site?.keywords} />
      <ListAll items={posts.edges} title='My Blog' pageSize={pageContext.pageSize} currentPage={pageContext.currentPage || 1}/>

      <Pagination pageSize={pageContext.pageSize} totalCount={posts.totalCount} currentPage={pageContext.currentPage || 1} skip={pageContext.skip} base='/projects' />
    </Layout>
  )
}

export default PostList
